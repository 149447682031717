import React, { useRef, useCallback, useState } from "react";
import styled from "@emotion/styled";
import FluidImagerX from "./elements/FluidImagerX";
import color from "../styles/colors";
import Button from "./elements/Button";
import { button } from "../styles/typography";
import { EffectFade, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import "swiper/swiper.scss";
import "swiper/modules/effect-fade/effect-fade.scss";
import "swiper/modules/navigation/navigation.scss";
import "swiper/modules/pagination/pagination.scss";
import {
	container,
	grid,
	gridc,
	spacingRestricted,
	screen,
} from "../styles/mixins";

const Section = styled.section`
  background-color: ${color("purple")};
  padding-bottom: 80px;
  color: ${color("white")};
  position: relative;
  overflow: hidden;

  @media ${screen("md")} {
    padding-bottom: 120px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${color("off-purple")};
    width: 100%;

    @media ${screen("md")} {
      height: calc(100% - 120px);
      border-bottom-right-radius: 100px;
    }

    @media ${screen("md")} {
      height: calc(100% - 180px);
    }
  }

  .SwiperClass {
    overflow: visible;

    .swiper-slide {
      opacity: 0;
      visibility: hidden;

      &.swiper-slide-active {
        opacity: 1;
        visibility: visible;
      }
    }

    .swiper-pagination  {
			display: flex;
			gap: 50px;
      padding-left: 4%;
      padding-right: 4%;
      padding-bottom: 20px;
			bottom: unset;
      width: calc(100% - 8%);
      top: 250px;
      overflow-x: auto;
      overflow-y: hidden;
      padding-right: 50px;

      @media ${screen("md")} {
        top: -90px;
        width: 100%;
        padding-left: 4%;
        margin-left: 0;
        margin-right: 0;
      }

      /* width */
      ::-webkit-scrollbar {
        width: 8px;
        height: 12px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: transparent;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: ${color("purple")};
        opacity: 0.1;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background-color: ${color("purple")};
      }

      @media ${screen("md")} {
        gap: 70px;
      }

      @media ${screen("lg")} {
        gap: 100px;
      }
		}

		.swiper-pagination-bullet {
      font-size: 18px;
      line-height: 1.3;
      position: relative;
			margin: 0;
      width: auto;
      height: auto;
			display: block;
      background-color: unset;
      opacity: 1;
      white-space: nowrap;

      @media ${screen("md")} {
        font-size: 22px;
      }

      &:before {
        content: '';
        position: absolute;
        bottom: -6px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: ${color("orange")};
        opacity: 0;
        visibility: 0;

        @media ${screen("md")} {
          bottom: -10px;
        }
      }

			&.swiper-pagination-bullet-active {
        color: ${color("beige")};

        &:before {
          opacity: 1;
          visibility: visible;
        }
			}
		}
  }
`;

const Container = styled.div`
  ${container()}
`;

const SwiperWrapper = styled.div`
  ${gridc(3, 44)}
  background-color: ${color("off-purple")};
  border-radius: 20px 100px 20px 20px;
  overflow: hidden;
  isolation: isolate;

  @media ${screen("md")} {
    ${gridc(1, 48)}
    padding-top: 146px;
    background-color: transparent;
    border-radius: 0;
  }
`;

const Item = styled.div`
  ${grid(48)}
`;

const ImageWrap = styled.div`
  overflow: hidden;
  position: relative;;
  padding-bottom: 230px;
  ${gridc(1, 49)};

  @media ${screen("md")} {
    ${gridc(1, 24)};
    border-radius: 0px 100px 20px 0px;
    padding-bottom: 0;
  }

  @media ${screen("lg")} {
    ${gridc(1, 26)};
  }
`;

const ContentWrap = styled.div`
  box-sizing: border-box;
  ${gridc(1, 49)};
  padding: 100px 30px 30px 30px;
  background-color: ${color("off-purple")};
  border-radius: 0px 0px 20px 20px;

  @media ${screen("md")} {
    ${gridc(28, 19)};
    padding: 0 0 130px 0;
    background-color: transparent;
    max-width: 380px;
  }

  @media ${screen("lg")} {
    padding: 0 0 160px 0;
    ${gridc(29, 20)};
  }
`;

const Subtitle = styled.span`
  display: block;
  font-size: 22px;
  line-height: 1.2;
  font-weight: 400;
  color: ${color("orange")};
  margin-bottom: 6px;

  @media ${screen("md")} {
    margin-bottom: 10px;
  }
`;

const Heading = styled.h2`
  font-size: 40px;
	line-height: 1;
  letter-spacing: -0.01em;
	font-weight: 500;
  margin-bottom: 30px;

	@media ${screen("md")} {
    font-size: 60px;
    margin-bottom: 45px;
	}
`;

const Highlight = styled.span`
  display: block;
  font-size: 18px;
  line-height: 1.2;
  color: ${color("beige")};
  margin-bottom: 16px;
`;

const Text = styled.div`
  font-size: 18px;
  line-height: 1.2;
`;

const Image = styled(FluidImagerX)`
	position: absolute !important;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;

const CtaButton = styled(Button)`
	width: 100%;
  margin-top: 30px;

	${button};
	${gridc(1, 42)}

  margin-bottom: 0 !important;

	@media ${screen("md")} {
		transform: none;
		width: auto;
		max-width: none;
		margin-right: 0.5em;
		margin-bottom: 0.5em;
    margin-top: 80px;
	}

  @media ${screen("lg")} {
    margin-top: 100px;
  }
`;

const BulletControl = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 30px;

  @media ${screen("md")} {
    display: none;
  }
`;

const Bullet = styled.button`
  padding: 0;
  margin: 0;
  border: 0;
  width: 10px;
  height: 10px;
  background-color: ${color("white")};
  overflow: hidden;
  border-radius: 100%;
  transition: .3s ease background-color;
  opacity: 0.3;

  &.active {
    opacity: 1;
  }

  &:active,
  &:focus {
    outline: none;
  }
`;

export default function FeaturedSlider({
  data
}) {
  const sliderRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(null);

  const pagination = {
		clickable: true,
		renderBullet: function (index, className) {
			return `<span class=${className}>${data[index].heading}</span>`
		},
	};

  const handleBulletClick = useCallback(idx => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideTo(idx);
  })

  function onSwiperChange (e) {
    setCurrentIndex(e.realIndex);
  }

	return data && data.length ? (
    <Section>
      <Container>
        <SwiperWrapper>
          <Swiper
            ref={sliderRef}
            effect={"fade"}
            onSlideChange={onSwiperChange}
            slidesPerView={1}
            allowTouchMove={true}
            autoHeight={false}
            autoplay={{
              delay: 5000,
              pauseOnMouseEnter: true,
              waitForTransition: true
            }}
            speed={500}
            fadeEffect={{ crossFade: true }}
            loop={true}
            className="SwiperClass"
            pagination={pagination}
            modules={[EffectFade, Pagination, Autoplay]}
            >
              {data.map((item, idx) => (
                <SwiperSlide key={idx}>
                  <Item key={idx}>
                    <ImageWrap>
                      <Image
                        image={item.backgroundImage[0]}
                        sizes="(min-width: 2200px) 1520px, (min-width: 720px) 50vw, 100vw"
                        ratio={1044 / 1017}
                        loading="eager"
                        alt={
                          item.backgroundImage[0]
                            ? item.backgroundImage[0].title
                            : null
                        }
                        gatsbyImage={false}
                      />
                    </ImageWrap>
                    <ContentWrap>
                      <Subtitle>{item.subHeading}</Subtitle>
                      <Heading>{item.heading}</Heading>
                      <Highlight><span dangerouslySetInnerHTML={{ __html: item.highlightText}} /></Highlight>
                      <Text><span dangerouslySetInnerHTML={{ __html: item.text}} /></Text>
                      {item?.button && (
                        <CtaButton
                          url={item.button.url}
                          target={item.button.target}
                          linkType={item.button.type}
                          element={item.button?.element}
                          external={item.button.type !== "entry"}
                          data-event-name="select_cta"
                          data-content-type="Go to page"
                          data-content-destination={item.button.url}
                          data-content-text={item.button.text}>
                          {item.button.text}
                        </CtaButton>
                      )}
                    </ContentWrap>
                  </Item>
                </SwiperSlide>
              ))}
          </Swiper>
            <BulletControl>
              {data.map((item, idx) => (
                <Bullet
                  className={currentIndex === idx ? "active" : null}
                  onClick={() => handleBulletClick(idx)}
                />
              ))}
            </BulletControl>
        </SwiperWrapper>
      </Container>
    </Section>
	) : null;
}